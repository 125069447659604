{/*More included in the video that I'm not sure I should add*/}

.MyCard
{
    margin: 20px;
}

.MyCard-image{
    border-radius: 10px;
    width: 300px;
    height: 480px;
    box-shadow: 0px 0px 3px 1px #ccc;
    background-color: #ccc;
}

.MyCard-title
{
    font-size: 24px;
    margin: 0px;
}

.MyCard-info
{
    margin-top:10px;
    min-height: 100px;
}

.MyCard-subTitle
{
    font-size:16px;
    margin:0px;
}

{/*Styles for feedback form messages*/}
.success-msg
{
    color: #57A773;
    {/*10 top and bottom, 15 left and right*/}
    padding: 10px 15px;

}

.error-msg
{
    color:#EE6352;
    padding: 10px 15px;
}
{/*For the resume*/}
.resume-container
{
    margin: 20px;
}
.resume-img
{
    width: 642px;
    height: 830px;
    box-shadow: 0px 0px 3px 1px #ccc;
    background-color: #ccc;
}

{/*For if I include an image gallery*/}
.img-gallery-container
{
    height:100px;
}

.img-gallery-img
{
    max-width: 100%;
        max-height: 100%;
        display: block;
}

