.img-gallery-container
{
    background-color:gainsboro;
    justify-content: center;
    align-content: center;
    height:70vh;
}

@media only screen and (max-width: 600px) {
    .img-gallery-container
{
    background-color:gainsboro;
    justify-content: center;
    align-content: center;
    height:40vh;
}
  }

.img-gallery-img
{
    height:100%;
    width: auto;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display: block;
}